import type { CardMediaAssetProps, CardCommonContainerProps } from './CardAsset';
import type { CardContentProps } from './CardContentA';
import type { CardContentEProps } from './CardContentE';
import type { UrlObject } from 'url';
import type { TypographyProps } from '@mui/material';

import * as React from 'react';
import { useEditorHandlers } from './@editor';
import styled from '@emotion/styled';
import { Card as MuiCard } from '@mui/material';
import CardActionArea from '@mui/material/CardActionArea';
import { Link } from '../Link';
import { CardAsset } from './CardAsset';
import { useAppContext } from '../../contexts/AppContext';
import { useRouter } from 'next/router';

import { CardContentA } from './CardContentA';
import { CardContentB } from './CardContentB';
import { CardContentC } from './CardContentC';
import { CardContentE } from './CardContentE';
import { CardContentF } from './CardContentF';
import { CardContentBanner } from './CardContentBanner';
import { CardContentFHardcoded } from './CardContentFHardcoded';
import { CardContentG } from './CardContentG';

import { CollectionContentA } from './CollectionContentA';
import { CollectionContentB } from './CollectionContentB';
import { CollectionContentC } from './CollectionContentC';
import { CollectionContentD } from './CollectionContentD';
import { CollectionContentE } from './CollectionContentE';

export interface CardProps extends CardMediaAssetProps, CardContentProps, CardContentEProps {
  href?: UrlObject | string;
  alt: string;
  src: string;
  width: number;
  height: number;
  company?: string;
  hoverEffect?: boolean;
  template?: string;
  typographyOptions?: TypographyProps;
  sx?: object;
  className?: string;
  innerFlex?: string;
  alignItems?: string;
  innerFlexSize?: string;
  cardBorder?: string;
  configuration?: any;
  hideDescription?: boolean;
  hideTitle?: boolean;
  hideDeveloperName?: boolean;
  hidePlayButton?: boolean;
  hideInfoSection?: boolean;
  assetsx?: any;
  configurationStyle?: any;
  collection?: {
    name: string;
    slug: string;
  };
}

const CardStyled = styled(MuiCard)(({ theme, template, hoverEffect, cardBorder }: any) => ({}));

const Inner = styled('div')(({ theme, innerFlex = 'column', innerAlign = 'normal' }: any) => ({
  display: 'flex',
  height: '100%',
  flexDirection: innerFlex,
  alignItems: innerAlign,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const templateComponents: Record<string, React.ElementType> = {
  default: CardContentA,
  simple: CardContentB,
  extensive: CardContentC,
  big: React.Fragment,
  cnn: CardContentF,
  banner: CardContentBanner,
  cnnactivities: CardContentFHardcoded,
  partial: CardContentE,
  CarouselA: CollectionContentA,
  CarouselB: CollectionContentB,
  CarouselC: CollectionContentE,
  CarouselD: CollectionContentE,
  SliderListC13Slick: CollectionContentC,
  FeatureGridA: CollectionContentC,
  FeatureGridB: CollectionContentC,
  ListViewA: CollectionContentD,
  BannerHorizontalA: CardContentG,
};

export const Card = (props: CardProps) => {
  const {
    href = '/',
    src,
    width,
    height,
    alt,
    template = 'default',
    title,
    company,
    description,
    className,
    sx,
    typographyOptions,
    hoverEffect,
    innerFlex,
    innerFlexSize,
    cardBorder,
    configuration,
    configurationStyle,
    hideDescription,
    hideTitle,
    hideDeveloperName,
    hidePlayButton,
    hideInfoSection,
    assetsx,
    collection,
    ...other
  } = props;

  const appContext = useAppContext();
  const { componentOptions } = appContext.useConfig();
  const { onGameCardClick } = appContext.useCollectionCrumbs();
  const router = useRouter();
  const basePath = router.basePath || '';

  const cardWidth = width || componentOptions.component_card_width || 100;
  const cardHeight = height || componentOptions.component_card_height || 100;

  const ContentComponent = templateComponents[template] || CardContentA;

  return (
    <CardStyled
      elevation={0}
      className={className}
      sx={sx}
      hoverEffect={hoverEffect}
      template={template}
      cardBorder={cardBorder}
      {...useEditorHandlers('Card', configuration, configurationStyle)}
    >
      <CardActionArea
        component={Link}
        onClick={() => onGameCardClick({ name: collection?.name || '', slug: collection?.slug || '' })}
        href={href}
        sx={{ height: '100%' }}
      >
        <Inner
          className="inner-card"
          innerFlexSize={innerFlexSize}
          innerFlex={innerFlex}
          style={{ alignItems: 'center' }}
        >
          <CardAsset
            innerFlexSize={innerFlexSize}
            src={`${basePath}${src}`}
            width={cardWidth}
            height={cardHeight}
            alt={alt}
            assetsx={assetsx}
            {...other}
          />
          {template === 'partial' ? (
            <ContentComponent width={width} height={height} {...other} />
          ) : (
            <ContentComponent
              title={title}
              description={description}
              hideDescription={hideDescription}
              hideTitle={hideTitle}
              hideDeveloperName={hideDeveloperName}
              hidePlayButton={hidePlayButton}
              hideInfoSection={hideInfoSection}
              company={company}
              {...typographyOptions}
              {...other}
            />
          )}
        </Inner>
      </CardActionArea>
    </CardStyled>
  );
};
