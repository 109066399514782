import * as React from 'react';
import styled from '@emotion/styled';
import { useHighlightHandlers } from '../../contexts/HighlightContext/Highlight';
import { Box, Typography as MuiTypography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { CustomButton } from '../CustomButton';
import { useTranslation } from 'next-i18next';

export interface CardContentProps {
  title?: string | undefined;
  description?: string | undefined;
  limit?: number | undefined;
  className?: string;
  hideDescription?: boolean | undefined;
  hidePlayButton?: boolean | undefined;
}

const Typography = React.forwardRef<HTMLInputElement, any>((props, ref) =>
  useHighlightHandlers(MuiTypography, { forwardedRef: ref, ...props })
);

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  width: '100%',
  paddingBottom: `${theme.spacing(1.5)} !important`,
  justifyContent: 'center',
  maxHeight: 85,
  [theme.breakpoints.up('sm')]: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
    padding: theme.spacing(1, 1.5),
    height: '100%',
    border: theme.theme_component_card_content_border || 'none',
    borderTop: 'none',
    pointerEvents: 'none',
  },
})) as typeof CardContent;

const CardTitle = styled(Typography)(({ theme }) => ({
  width: '100%',
  display: 'inline-block',
  color: '#000',
}));

const CardContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  '& span': {
    flex: 1,
    marginRight:theme.spacing(1)
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  height: 'auto',
  minHeight: '45px',
  display: '-webkit-box',
  '-webkit-line-clamp': '2',
  overflow: 'hidden',
  'text-overflow': 'ellipsis',
  '-webkit-box-orient': 'vertical',
}));

const ButtonStyled = styled(CustomButton)(({ theme }) => ({
  display: 'flex',
  margin: 0,
  pointerEvents: 'auto',
  alignSelf: 'center',
  padding: theme.spacing(.4, 4.5),
  borderRadius: theme.spacing(9),
  [theme.breakpoints.down(1300)]: {
    padding: '0px 4px',
  },
}));

const getText = (val: string, limit: number) => {
  if (val !== '' && val !== null && val !== undefined && val?.length > limit) {
    return `${val.slice(0, limit - 3)}...`;
  }
  return val;
};

// Todo: HUZ2ERA-1101 - Allow Typography props to be drilled (now we have them hardcoded in our jsx).
//       Had trouble extending our CardContentCProps...
export const CollectionContentA = (props: CardContentProps) => {
  const { description, hidePlayButton, title, limit, className, hideDescription, ...other } = props;
  const body2Ref = React.useRef<HTMLInputElement | null>(null);

    const { t } = useTranslation('card_content_c');

  return (
    <CardContentStyled className={className}>
      <CardContentWrapper>
        <CardTitle variant="caption" noWrap {...other}>
          {getText(title || '', limit || 35)}
        </CardTitle>
        {!hideDescription && (
          <Description ref={body2Ref} variant="body2" color="textPrimary" {...other}>
            {description && getText(description, limit || 75)}
          </Description>
        )}
        {!hidePlayButton && (
          <ButtonStyled
            variant="contained"
            disableElevation
            disableRipple
            disableFocusRipple
            size="small"
            className="card-content-button"
          >
            {t('play_now', 'Play')}
          </ButtonStyled>
        )}
      </CardContentWrapper>
    </CardContentStyled>
  );
};
