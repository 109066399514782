import type { ImageLoaderWrapperProps } from '../ImageLoaderWrapper';

import * as React from 'react';
import styled from '@emotion/styled';
import { ImageLoaderWrapper } from '../ImageLoaderWrapper';
import CardMedia from '@mui/material/CardMedia';

export interface CardMediaAssetProps extends ImageLoaderWrapperProps {
  alt: string;
  className?: string;
  innerFlexSize?: string;
  assetsx?: any;
}
export type CardCommonContainerProps = {
  children: JSX.Element;
};

const CardMediaStyled = styled(({ innerFlexSize, sx, ...other }: any) => <CardMedia {...other} />)(
  ({ theme, innerFlexSize = 'auto', sx }) => ({
    width: '100%',
    height: '100%',
    backgroundColor: 'none',
    pointerEvents: 'none',
    marginBottom: 'auto',
    '& span:first-of-type': {
      display: 'block !important',
    },
    flex: innerFlexSize,
    [theme.breakpoints.down('md')]: {
      flex: 'auto',
    },
    '& span': {
      height: '100% !important',
    },
    ...sx,
  })
);

export const CardAsset = (props: CardMediaAssetProps) => {
  const { width, height, className, innerFlexSize, assetsx, ...other } = props;
  return (
    <CardMediaStyled className={className} innerFlexSize={innerFlexSize} sx={assetsx}>
      <div
        className="card-img"
        style={{
          display: 'block',
          position: 'relative',
          height: '100%',
          // aspectRatio: `${width / height}`,
        }}
      >
        <ImageLoaderWrapper width={width} height={height} {...other} />
      </div>
    </CardMediaStyled>
  );
};
