import * as React from 'react';
import styled from '@emotion/styled';
import { useHighlightHandlers } from '../../contexts/HighlightContext/Highlight';
import { Button, Typography as MuiTypography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { useTranslation } from 'next-i18next';
import { CustomButton } from '../CustomButton';
import { Title } from '../Title';

export interface CardContentProps {
  title?: string | undefined;
  description?: string | undefined;
  limit?: number | undefined;

  hideDescription?: boolean | undefined;
  className?: string;
}

const Typography = React.forwardRef<HTMLInputElement, any>((props, ref) =>
  useHighlightHandlers(MuiTypography, { forwardedRef: ref, ...props })
);

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  textAlign: 'left',
  padding: theme.spacing(2),
  height: '100%',
  border: theme.theme_component_card_content_border || 'none',
  borderTop: 'none',
  pointerEvents: 'none',
  flex: 1,
  minHeight: 132,
})) as typeof CardContent;

const CardContentInfo = styled('div')(({ theme }) => ({
  display: 'grid',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  width: '100%',
  display: 'inline-block',
  color: '#000',
  marginRight: theme.spacing(1.5),
  // @ts-ignore
  fontSize: `${theme.typography.h1.fontSize.split('rem')[0] * 0.9167}rem`,
  [theme.breakpoints.down('cxxl')]: {
    // @ts-ignore
    fontSize: `${theme.typography.h1.fontSize.split('rem')[0] * 0.625333}rem`,
  },
  [theme.breakpoints.down('cxl')]: {
    // @ts-ignore
    fontSize: `${theme.typography.h1.fontSize.split('rem')[0] * 0.583333}rem`,
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  height: 'auto',
  fontWeight: theme.typography.h3.fontWeight,
  // @ts-ignore
  fontSize: `${theme.typography.h1.fontSize.split('rem')[0] * 0.9167}rem`,
  [theme.breakpoints.down('cxxl')]: {
    // @ts-ignore
    fontSize: `${theme.typography.h1.fontSize.split('rem')[0] * 0.625333}rem`,
  },
  [theme.breakpoints.down('cxl')]: {
    // @ts-ignore
    fontSize: `${theme.typography.h1.fontSize.split('rem')[0] * 0.583333}rem`,
  },
}));

const ButtonStyled = styled(CustomButton)(({ theme }) => ({
  display: 'flex',
  margin: 0,
  pointerEvents: 'auto',
  alignSelf: 'center',
  padding: theme.spacing(1.5, 6.75),
  borderRadius: theme.spacing(9),
}));

const getText = (val: string, limit: number) => {
  if (val !== '' && val !== null && val !== undefined && val?.length > limit) {
    return `${val.slice(0, limit - 3)}...`;
  }
  return val;
};

export const CollectionContentC = (props: any) => {
  const {
    description,
    title,
    limit,
    className,

    company,
    hideDeveloperName = false,
    hidePlayButton,
    hideInfoSection,
    hideDescription = true,
    hideTitle,
    ...other
  } = props;
  const { t } = useTranslation('card_content_c');
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);

  return (
    <>
      {(title || description || company) && !hideInfoSection && (
        <CardContentStyled className="card-content">
          <CardContentInfo className="card-content-info">
            {!hideTitle && (
              <CardTitle variant={'h1'} noWrap {...other}>
                {getText(title || '', limit || 35)}
              </CardTitle>
            )}
            {!hideDeveloperName && (
              <Description variant="body2" color="textPrimary" {...other}>
                {company && getText(company, limit || 35)}
              </Description>
            )}
            {!hideDescription && (
              <Description variant="body2" color="textPrimary" {...other}>
                {description && getText(description, limit || 80)}
              </Description>
            )}
          </CardContentInfo>
          {!hidePlayButton && (
            <ButtonStyled
              ref={buttonRef}
              variant="contained"
              disableElevation
              disableRipple
              disableFocusRipple
              size="small"
              className="card-content-button"
            >
              {t('play_now', 'Play')}
            </ButtonStyled>
          )}
        </CardContentStyled>
      )}
    </>
  );
};
