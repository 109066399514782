import * as React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import { useHighlightHandlers } from '../../contexts/HighlightContext/Highlight';
import { Typography as MuiTypography } from '@mui/material';
import { Button as MuiButton } from '@mui/material';
import CardContent from '@mui/material/CardContent';

export interface CardContentGProps {
  title?: string | undefined;
  description?: string | undefined;
  limit?: number | undefined;
  className?: string;
  hideDescription?: boolean;
  hideDeveloperName?: boolean;
  children?: React.ReactNode;
  company?: string;
}

const Typography = React.forwardRef<HTMLInputElement, any>((props, ref) =>
  useHighlightHandlers(MuiTypography, { forwardedRef: ref, ...props })
);

const Button = React.forwardRef<HTMLButtonElement, any>((props, ref) =>
  useHighlightHandlers(MuiButton, { forwardedRef: ref, ...props })
);

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  width: '300px',
  minWidth: '300px',
  display: 'flex',
  flexDirection: 'column',
  height: '80%',
  padding: theme.spacing(4),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(2),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
    height: '90%',
    width: '100%',
    minWidth: 'unset',
  },
}));

const ContentSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'flex-start',
  backgroundColor: theme.palette.background.paper,
  height: '100%',
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 'bold',

  color: theme.palette.text.primary,
}));

const DeveloperName = styled(Typography)(({ theme }) => ({
  height: 'auto',
  fontSize: theme.typography.h3.fontSize,
  fontWeight: theme.typography.h3.fontWeight,
  color: theme.palette.text.secondary,
}));

const Description = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(2),
  marginBottom: theme.spacing(3),
  color: theme.palette.text.secondary,
}));

const PlayButton = styled(Button)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1, 3),
  color: theme.theme_component_card_content_button_color || theme.palette.primary.contrastText,
  background: theme.theme_component_card_content_button_background || theme.palette.primary.main,
  borderRadius: theme.theme_component_feature_grid_a_card_button_border_radius
    ? `${theme.theme_component_feature_grid_a_card_button_border_radius}px`
    : '16px',
  fontSize: theme.theme_component_feature_grid_a_card_button_font_size
    ? `${theme.theme_component_feature_grid_a_card_button_font_size}px`
    : '16px',
  fontWeight: theme.theme_component_feature_grid_a_card_button_font_weight || 'normal',
  border: '1px solid #ebebeb',
  [theme.breakpoints.down('md')]: {
    width: 'fit-content',
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const getText = (val: string, limit: number) => {
  if (val !== '' && val !== null && val !== undefined && val?.length > limit) {
    return `${val.slice(0, limit - 3)}...`;
  }
  return val;
};

export const CardContentG = (props: CardContentGProps) => {
  const {
    description,
    hideDescription = true,
    title,
    limit,
    className,
    children,

    company,
    hideDeveloperName,
    ...other
  } = props;
  const { t } = useTranslation('card_content_g');
  console.log('hideDescription: 111: ', hideDescription);
  return (
    <CardContentStyled className={className}>
      <ContentSection>
        <Title variant="h5" component="h3" {...other}>
          {getText(title || '', limit || 35)}
        </Title>
        {!hideDeveloperName && company && (
          <DeveloperName variant="body1" component="p" {...other}>
            {company && getText(company, limit || 35)}
          </DeveloperName>
        )}
        {!hideDescription && description && (
          <Description variant="body1" component="p" {...other}>
            {description && getText(description, limit || 100)}
          </Description>
        )}
      </ContentSection>
      <PlayButton variant="contained">{t('play_now', 'Play')}</PlayButton>
    </CardContentStyled>
  );
};
