import * as React from 'react';
import styled from '@emotion/styled';
import { useHighlightHandlers } from '../../contexts/HighlightContext/Highlight';
import { Typography as MuiTypography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { CardProps } from './Card';
import { useTranslation } from 'next-i18next';
import { CustomButton } from '../CustomButton';

export interface CardContentProps extends CardProps {
  title?: string | undefined;
  limit?: number | undefined;
  description?: string | undefined;
  hideDescription?: boolean | undefined;
  hidePlayButton?: boolean | undefined;
  className?: string;
}

const Typography = React.forwardRef<HTMLInputElement, any>((props, ref) =>
  useHighlightHandlers(MuiTypography, { forwardedRef: ref, ...props })
);

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  textAlign: 'left',
  padding: theme.spacing(2),
  height: '100%',
  border: theme.theme_component_card_content_border || 'none',
  borderTop: 'none',
  pointerEvents: 'none',
  flex: 1,
})) as typeof CardContent;

const CardContentInfo = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  width: '100%',
  display: 'inline-block',
  color: '#000',
}));

const DeveloperName = styled(Typography)(({ theme }) => ({
  height: 'auto',
}));

const ButtonStyled = styled(CustomButton)(({ theme }) => ({
  display: 'inline-block',
  margin: 0,
  pointerEvents: 'auto',
  alignSelf: 'center',
}));

const getText = (val: string, limit: number) => {
  if (val !== '' && val !== null && val !== undefined && val?.length > limit) {
    return `${val.slice(0, limit - 3)}...`;
  }
  return val;
};

export const CollectionContentE = (props: CardContentProps) => {
  const {
    hideTitle,
    title,
    hideDeveloperName,

    company,
    limit,
    className,
    hidePlayButton,
    ...other
  } = props;

  const { t } = useTranslation('card_content_e');
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);

  return (
    <>
      {(title || company) && (
        <CardContentStyled className={className}>
          <CardContentInfo>
            {!hideTitle && (
              <CardTitle variant="h3" noWrap {...other}>
                {getText(title || '', limit || 30)}
              </CardTitle>
            )}
            {!hideDeveloperName && (
              <DeveloperName variant="body2" color="textPrimary" {...other}>
                {company && getText(company, limit || 35)}
              </DeveloperName>
            )}
          </CardContentInfo>
          {!hidePlayButton && (
            <ButtonStyled
              ref={buttonRef}
              variant="contained"
              disableElevation
              disableRipple
              disableFocusRipple
              size="small"
            >
              {t('play_now', 'Play')}
            </ButtonStyled>
          )}
        </CardContentStyled>
      )}
    </>
  );
};
