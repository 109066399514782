import * as React from 'react';
import styled from '@emotion/styled';
import { useHighlightHandlers } from '../../contexts/HighlightContext/Highlight';
import { Typography as MuiTypography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { useTranslation } from 'next-i18next';

export interface CardContentProps {
  title?: string | undefined;
  description?: string | undefined;
  limit?: number | undefined;
  hideDescription?: boolean | undefined;
  className?: string;
}

const Typography = React.forwardRef<HTMLInputElement, any>((props, ref) =>
  useHighlightHandlers(MuiTypography, { forwardedRef: ref, ...props })
);

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    textAlign: 'left',
    height: '100%',
    border: theme.theme_component_card_content_border || 'none',
    borderTop: 'none',
    pointerEvents: 'none',
    flex: 1,
    padding: 0,
    paddingBottom: '0 !important',
    paddingLeft: theme.spacing(1.5),
  },
})) as typeof CardContent;

const CardContentInfo = styled('div')(({ theme }) => ({
  flexDirection: 'column',
  flex: 1,
  '-webkit-line-clamp': '1',
  overflow: 'hidden',
  'text-overflow': 'ellipsis',
  '-webkit-box-orient': 'vertical',
  display: '-webkit-box',
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  width: '100%',
  display: 'inline-block',
  color: '#000',
}));

const Description = styled(Typography)(({ theme }) => ({
  height: 'auto',
  display: '-webkit-box',
  '-webkit-line-clamp': '2',
  overflow: 'hidden',
  'text-overflow': 'ellipsis',
  '-webkit-box-orient': 'vertical',
}));

const getText = (val: string, limit: number) => {
  if (val !== '' && val !== null && val !== undefined && val?.length > limit) {
    return `${val.slice(0, limit - 3)}...`;
  }
  return val;
};

export const CollectionContentD = (props: any) => {
  const {
    description,
    title,
    limit,
    className,
    company,
    hideInfoSection,
    hideDeveloperName = false,
    hideDescription = true,
    ...other
  } = props;
  const { t } = useTranslation('card_content_c');

  return (
    <>
      {(title || description || company) && !hideInfoSection && (
        <CardContentStyled className={className}>
          <CardContentInfo>
            <CardTitle variant="caption" noWrap {...other}>
              {getText(title || '', limit || 35)}
            </CardTitle>
            {!hideDeveloperName && (
              <Description variant="body2" color="textPrimary" {...other}>
                {company && getText(company, limit || 80)}
              </Description>
            )}
            {!hideDescription && (
              <Description variant="body2" color="textPrimary" {...other}>
                {description && getText(description, limit || 80)}
              </Description>
            )}
          </CardContentInfo>
        </CardContentStyled>
      )}
    </>
  );
};
